<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
               <div class="form-body">
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="companyName" @md-selected="getCompanySelected" :md-options="companies" @md-changed="getCompanies"  @md-opened="getCompanies" :class="{'md-invalid': submitted && $v.entity.companyId.$error }">
                                    <label>Công ty</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.companyId.required">Vui lòng chọn công ty</span>
                                </md-autocomplete>
                                <md-button @click="openCompany()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers" :class="{'md-invalid': submitted && $v.entity.customerId.$error }">
                                    <label>Khách hàng<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.customerId.isSelected">Vui lòng chọn khách hàng</span>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tax">Mã số thuế</label>
                                <md-input disabled name="paymentNo" v-model="entity.customer.taxCode"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.paymentDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.paymentDate.$error }">
                                <label>Ngày chứng từ<span class="label-require">(*)</span></label>
                                <span class="md-error" v-if="submitted && !$v.entity.paymentDate.required">Vui lòng chọn ngày chứng từ</span>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Nhân viên<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên xuất hóa đơn</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                <label for="invoiceCode">Ký hiệu<span class="label-require">(*)</span></label>
                                <md-input name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập ký hiệu hóa đơn</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="note">Diễn giải</label>
                                <md-input name="note" v-model="entity.note"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.paymentNo.$error }">
                                <label for="paymentNo">Số chứng từ<span class="label-require">(*)</span></label>
                                <md-input name="paymentNo" v-model="entity.paymentNo"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.paymentNo.required">Vui lòng nhập số chứng từ</span>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.account.$error }">
                                <label for="account">Tài khoản<span class="label-require">(*)</span></label>
                                <md-input name="account" v-model="entity.account"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.account.required">Vui lòng nhập tài khoản</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.accountName.$error }">
                                <label for="accountName">Tên tài khoản<span class="label-require">(*)</span></label>
                                <md-input name="accountName" v-model="entity.accountName"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.accountName.required">Vui lòng nhập tên tài khoản</span>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="currencyCode">Mã ngoại tệ</label>
                                <md-input name="currencyCode" v-model="entity.currencyCode"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Tỷ giá</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="rate" v-model="entity.rate" v-bind="number"></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div>Phát sinh nợ ngoại tệ</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="foreignDebt" v-model="entity.foreignDebt" v-bind="number"></number> 
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Phát sinh có ngoại tệ</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="foreignCredit" v-model="entity.foreignCredit" v-bind="number"></number> 
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Phát sinh nợ</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="debt" v-model="entity.debt" v-bind="number"></number> 
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Phát sinh có</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="credit" v-model="entity.credit" v-bind="number"></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="accountCode">Ký hiệu</label>
                                <md-input name="accountCode" v-model="entity.accountCode"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                <label>Ngày hóa đơn<span class="label-require">(*)</span></label>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày hóa đơn</span>
                            </md-datepicker>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Tiền hàng ngoại tệ</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="foreignCash" v-model="entity.foreignCash" v-bind="number"></number> 
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Tiền hàng</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="cash" v-model="entity.cash" v-bind="number"></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="noteDetail">Diễn giải chi tiết</label>
                                <md-input name="noteDetail" v-model="entity.noteDetail"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tempReport">Mẫu báo cáo</label>
                                <md-input name="tempReport" v-model="entity.tempReport"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="paymentCode">Mã tính chất</label>
                                <md-input name="paymentCode" v-model="entity.paymentCode"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="tempInvoice">Mẫu hóa đơn</label>
                                <md-input name="tempInvoice" v-model="entity.tempInvoice"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="supplierName" @md-selected="getSupplierSelected" :md-options="suppliers" @md-changed="getSuppliers"  @md-opened="getSuppliers" :class="{'md-invalid': submitted && $v.entity.supplierId.$error }">
                                    <label>Nhà cung cấp<span class="label-require">(*)</span></label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.supplierId.isSelected">Vui lòng chọn nhà cung cấp</span>
                                </md-autocomplete>
                                <md-button @click="openSupplier()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="companyName">Tên nhà cung cấp</label>
                                <md-input disabled name="companyName" v-model="entity.supplier.companyName"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="address">Địa chỉ</label>
                                <md-input disabled name="address" v-model="entity.supplier.address"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="taxCode">Mã số thuế</label>
                                <md-input disabled name="taxCode" v-model="entity.supplier.taxCode"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="serviceName">Tên hàng hóa - dịch vụ</label>
                                <md-input name="serviceName" v-model="entity.serviceName"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="cashNote">Ghi chú</label>
                                <md-input name="cashNote" v-model="entity.cashNote"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="task">Vụ việc</label>
                                <md-input name="task" v-model="entity.task"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="department">Bộ phận</label>
                                <md-input name="department" v-model="entity.department"></md-input>
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="lsx">Lsx</label>
                                <md-input name="lsx" v-model="entity.lsx"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="product">Sản phẩm</label>
                                <md-input name="product" v-model="entity.product"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="contract">Hợp đồng</label>
                                <md-input name="contract" v-model="entity.contract"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <div>Phí</div>
                            <md-field class="number-control" style="min-height: 30px; padding-top: 10px; flex-direction: column; align-items: start;">
                                <number name="amount" v-model="entity.amount" v-bind="number"></number> 
                            </md-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="kheUoc">Khế ước</label>
                                <md-input name="kheUoc" v-model="entity.kheUoc"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-3 m-3 c-12">
                            <md-field>
                                <label for="nhomDinhKhoan">Nhóm định khoản</label>
                                <md-input name="nhomDinhKhoan" v-model="entity.nhomDinhKhoan"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <supplierList ref="supplierList" title="nhà cung cấp" :type="3" @close="closeSupplier"/>
        <staffList ref="staffList" @close="closeStaff"/>
   </div>
</template>
<script>
    import accountantInvoiceCustomerService from '../../../api/accountantInvoiceCustomerService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import supplierList from '../../../components/popup/_CompanyList.vue';
    import staffList from '../../../components/popup/_StaffList.vue';

    export default ({
        components: {
            customerList,
            staffList,
            supplierList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật hóa đơn cho nhà cung cấp'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, supplierId: 0, kheUoc: '', nhomDinhKhoan: '', amount: 0, contract: '', product: '', lsx: '', department: '', task: '', serviceName: '', cashNote: '', foreignCash: 0, cash: 0, paymentCode: '', invoiceDate: common.dateNow, supplier: { companyName: '', taxCode: '', address: '' }, accountCode: '', tempInvoice: '', tempReport: '', noteDetail: '', foreignDebt: 0, foreignCredit: 0, debt: 0, credit: 0, account: '', currencyCode: 'VNĐ', rate: 1, accountName: '', companyId: 0, customerId: 0, customer: { taxCode: '', companyAlias: '' }, staffId: 0, paymentDate: common.dateNow, paymentNo: '', invoiceCode: '', note: '' },
                customerName: '',
                customers: [],
                companies: [],
                companyName: '',
                staffName: '',
                staffs: [],
                customerType: common.companyType.customer,
                supplierName: '',
                suppliers: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getCompany();
            if(this.id > 0){
                this.title = 'Cập nhật hóa đơn cho nhà cung cấp';
                this.getById();
            }
            else{
                this.getByAccount();
                this.title = 'Thêm mới hóa đơn cho nhà cung cấp';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            calSum(){
                this.entity.amount = parseFloat(this.entity.quantity) * parseFloat(this.entity.price) * parseFloat(this.entity.rate);
            },

            getCompany(){
                companyService.getOwner().then((response) => {
                    if(response.statusCode == 200){
                        this.entity.companyId = response.data.id;
                        this.companyName = response.data.companyAlias;
                        this.companies = [];
                        this.companies.push(response.data);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getCompanySelected(val){
                this.entity.companyId = val.id;
                this.companyName = val.companyAlias;
            },

            //Supplier
            closeSupplier(item){
                this.supplierName = item.companyAlias;
                this.entity.supplierId = item.id;
                this.entity.supplier = item;
                this.$refs.supplierList.close();
            },

            getSupplierSelected(val){
                this.entity.supplierId = val.id;
                this.supplierName = val.companyAlias;
                this.entity.supplier = val;
            },

            openSupplier(){
                this.$refs.supplierList.open(3);
            },

            getSuppliers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.supplier, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.suppliers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.entity.customerId = item.id;
                this.entity.customer = item;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.entity.customerId = val.id;
                this.customerName = val.companyAlias;
                this.entity.customer = val;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: common.companyType.customer, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                accountantInvoiceCustomerService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                accountantInvoiceCustomerService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/accountant-invoice-customer');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                accountantInvoiceCustomerService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = response.data.staff.fullName;
                        this.customerName = response.data.customer.companyAlias;
                        this.supplierName = response.data.supplier.companyAlias;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.staffName = response.data.fullName;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.$refs.staffList.close();
            },

            getStaffSelected(item){
                this.entity.staffId = item.id;
                this.staffName = item.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },
            back(){
                this.$router.push('/accountant-invoice-customer');
            }
        },
        watch: { 
            companyName: function (val) { 
                if(val == ''){
                    this.entity.companyId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.entity.customerId = 0;
                    this.entity.customer = {};
                }
            },
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
            supplierName: function (val) { 
                if(val == ''){
                    this.entity.supplierId = 0;
                }
            },
        },
        validations: {
            entity: {
                companyId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                customerId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                supplierId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                paymentNo: { required },
                paymentDate: { required },
                invoiceCode: { required },
                account: { required },
                accountName: { required },
                invoiceDate: { required },
            }
        }
     })

</script>
